import React from "react";
import { useState, useEffect } from "react";
import "./App.css";
import mondaySdk from "monday-sdk-js";
import "monday-ui-react-core/dist/main.css";
import "monday-ui-react-core/tokens";
import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

const monday = mondaySdk();

// Create a client
const queryClient = new QueryClient();

function Home() {
  const [context, setContext] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initMonday = async () => {
      try {
        monday.execute("valueCreatedForUser");
        const currentContext = await monday.get("context");
        setContext(currentContext.data);
      } catch (error) {
        console.error("Error initializing monday context:", error);
      } finally {
        setIsLoading(false);
      }
    };

    initMonday();
  }, []);

  if (isLoading) return <ShimerLoading />;

  if (!context) {
    return (
      <div className="flex flex-col justify-center items-center min-h-screen bg-gradient-to-b from-gray-50 to-white p-6">
        <div className="max-w-2xl w-full p-8">
          <h2 className="text-3xl font-bold mb-6 text-gray-600 text-center">
            Deep Links for Contacts
          </h2>
          <p className="text-gray-700 mb-6 text-center">
            Enhance your contact management with deep links for WhatsApp,
            Telegram, and more, directly in your monday.com boards.
          </p>
          <div className="bg-gray-50 p-6 rounded-lg mb-6">
            <h3 className="font-semibold mb-4 text-lg text-gray-800">
              How to Get Started:
            </h3>
            <ol className="list-decimal list-inside space-y-4 text-gray-700">
              <li>
                Add a 'Phone' column to your board (if not already present)
              </li>
              <li>Enter phone numbers in the 'Phone' column</li>
              <li>
                Use this app to manage and interact with the phone numbers
              </li>
            </ol>
          </div>
        </div>
      </div>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ContextItem itemId={context.itemId} />
    </QueryClientProvider>
  );
}

export default Home;

const ContextItem = ({ itemId }) => {
  const {
    data: item,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["item", itemId],
    queryFn: async () => {
      const res = await monday.api(`
          query {
            items(ids: [${itemId}]) {
              id
              name
              column_values{
                id
                value
                text
                type
              }
              group{
                id
              }
              board{
                type
              }
            }
          }
        `);
      return res.data.items[0];
    },
  });

  if (isLoading) return <ShimerLoading />;
  if (error)
    return (
      <p className="text-center text-red-500">
        You are not allowed to use this app as a viewer. Please contact your
        administrator for access.
      </p>
    );

  const findMyPhone = (columnValues) => {
    let phones = [];
    if (columnValues.length > 0) {
      columnValues.forEach((column) => {
        if (column.type === "phone" && column.value) {
          try {
            const phoneData = JSON.parse(column.value);
            if (phoneData.phone) {
              phones.push({
                phone: phoneData.phone,
                countryShortName: phoneData.countryShortName,
              });
            }
          } catch (e) {
            console.error("Error parsing phone data:", e);
          }
        }
      });
    }
    return phones;
  };

  const generateDeepLink = (phone, service) => {
    switch (service) {
      case "WhatsApp":
        return `https://wa.me/${phone}`;
      case "Telegram":
        return `https://t.me/+${phone}`;
      case "Viber":
        return `viber://contact?number=${phone}`;
      case "Apple Messages":
        return `sms:${phone}`;
      default:
        return "#";
    }
  };

  const phones = findMyPhone(item.column_values);

  console.log("phones", phones);

  return (
    <div className="mx-2 p-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-semibold text-center text-gray-800 mb-4">
        Deep Links
      </h1>
      {phones.length > 0 ? (
        <ul className="space-y-4">
          {phones.map((phoneObj, index) => (
            <li key={index} className="p-4 bg-gray-100 rounded-lg shadow">
              <div className="flex items-center justify-between">
                <span className="text-lg font-medium text-gray-700">
                  {phoneObj.phone} ({phoneObj.countryShortName})
                </span>
              </div>
              <div className="mt-2 flex space-x-4">
                <a
                  href={generateDeepLink(phoneObj.phone, "WhatsApp")}
                  className="text-white bg-green-500 hover:bg-green-600 px-3 py-2 rounded-md text-sm font-medium"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  WhatsApp
                </a>
                <a
                  href={generateDeepLink(phoneObj.phone, "Telegram")}
                  className="text-white bg-gray-500 hover:bg-gray-600 px-3 py-2 rounded-md text-sm font-medium"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Telegram
                </a>
                <a
                  href={generateDeepLink(phoneObj.phone, "Viber")}
                  className="text-white bg-purple-500 hover:bg-purple-600 px-3 py-2 rounded-md text-sm font-medium"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Viber
                </a>
                <a
                  href={generateDeepLink(phoneObj.phone, "Apple Messages")}
                  className="text-white bg-gray-500 hover:bg-gray-600 px-3 py-2 rounded-md text-sm font-medium"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Apple Messages
                </a>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className="flex flex-col justify-center items-center min-h-screen bg-gradient-to-b from-gray-50 to-white p-6">
          <div className="max-w-2xl w-full p-8">
            <h2 className="text-3xl font-bold mb-6 text-gray-600 text-center">
              Deep Links for Contacts
            </h2>
            <p className="text-gray-700 mb-6 text-center">
              Enhance your contact management with deep links for WhatsApp,
              Telegram, and more, directly in your monday.com boards.
            </p>
            <div className="bg-gray-50 p-6 rounded-lg mb-6">
              <h3 className="font-semibold mb-4 text-lg text-gray-800">
                How to Get Started:
              </h3>
              <ol className="list-decimal list-inside space-y-4 text-gray-700">
                <li>
                  Add a 'Phone' column to your board (if not already present)
                </li>
                <li>Enter phone numbers in the 'Phone' column</li>
                <li>
                  Use this app to manage and interact with the phone numbers
                </li>
              </ol>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ShimerLoading = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-pulse">
        <div className="bg-gray-300 h-4 w-48 rounded mb-2"></div>
        <div className="bg-gray-300 h-4 w-72 rounded mb-2"></div>
        <div className="bg-gray-300 h-4 w-64 rounded mb-2"></div>
        <div className="bg-gray-300 h-4 w-80 rounded"></div>
      </div>
    </div>
  );
};
